import request from '@/utils/http'

/**
 * cms列表
 * @param payload
 */
export function getArticleList(payload) {
    return request({ url: '/cms/Article/lists', method: 'post', data: payload })
}

/**
 * 详情-编辑
 * @param payload
 */
export function getArticleDetail(payload) {
    return request({ url: '/cms/Article/detail', method: 'post', data: payload })
}

/**
 * 添加文档
 * @param payload
 */
export function articleCreate(payload) {
    return request({ url: '/cms/Article/create', method: 'post', data: payload })
}

/**
 * 编辑文档
 * @param payload
 */
export function articleUpdate(payload) {
    return request({ url: '/cms/Article/update', method: 'post', data: payload })
}

/**
 * 编辑排序
 * @param payload
 */
export function asort(payload) {
    return request({ url: '/cms/Article/sort', method: 'post', data: payload })
}

/**
 * 删除文档
 * @param payload
 */
export function articleDeleteByIds(payload) {
    return request({ url: '/cms/Article/delete', method: 'post', data: payload })
}

/**
 * 移动文档
 * @param payload
 */
export function articleMoveByIds(payload) {
    return request({ url: '/cms/Article/move', method: 'post', data: payload })
}

/**
 * 上架文档
 * @param payload
 */
export function articleOnderByIds(payload) {
    return request({ url: '/cms/Article/onder', method: 'post', data: payload })
}

/**
 * 下架文档
 * @param payload
 */
export function articleUnderByIds(payload) {
    return request({ url: '/cms/Article/under', method: 'post', data: payload })
}

/**
 * 回收站列表
 * @param payload
 */
export function getArticleRecycleList(payload) {
    return request({ url: '/cms/Article/recycle', method: 'post', data: payload })
}

/**
 * 回收站列表
 * @param payload
 */
export function getArticleDraftList(payload) {
    return request({ url: '/cms/Article/draft', method: 'post', data: payload })
}

/**
 * 真实删除
 * @param payload
 */
export function articleforceDeleteByIds(payload) {
    return request({ url: '/cms/Article/forcedel', method: 'post', data: payload })
}

/**
 * 清空回收站
 * @param payload
 */
export function articleEmptyRecycle(payload) {
    return request({ url: '/cms/Article/okempty', method: 'post', data: payload })
}

/**
 * 还原
 * @param payload
 */
export function articleRenewByIds(payload) {
    return request({ url: '/cms/Article/renew', method: 'post', data: payload })
}

/**
 * 栏目列表
 * @param payload
 */
export function getCategoryList(payload) {
    return request({ url: '/cms/Category/index', method: 'get', data: payload })
}

/**
 * 检查栏目是否有发布权限
 * @param payload
 */
export function check(payload) {
    return request({ url: '/cms/Category/check', method: 'post', data: payload })
}

/**
 * 栏目添加
 * @param payload
 */
export function categoryCreate(payload) {
    return request({ url: '/cms/Category/create', method: 'post', data: payload })
}

/**
 * 栏目修改
 * @param payload
 */
export function categoryUpdate(payload) {
    return request({ url: '/cms/Category/update', method: 'post', data: payload })
}

/**
 * 栏目删除
 * @param payload
 */
export function categoryDelete(payload) {
    return request({ url: '/cms/Category/delete', method: 'post', data: payload })
}

/**
 * 栏目详情
 * @param payload
 */
export function categoryDetail(payload) {
    return request({ url: '/cms/Category/read', method: 'post', data: payload })
}

/**
 * 栏目排序
 * @param payload
 */
export function csort(payload) {
    return request({ url: '/cms/Category/sort', method: 'post', data: payload })
}
/**
 * 上传视频
 * @param payload
 */
export let upload = process.env.VUE_APP_API_BASE_URL + '/storage/Upload/file';
